<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title" v-if="formType == 2">预约赎货</div>
      <div class="erp-form__title" v-if="formType == 23">编辑预约赎货</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="submit">保存</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <div class="mali-edit__form">
          <el-row :gutter="16" type="flex">
            <el-col :span="12">
              <el-form-item label="结算日期" prop="settleDate">
                <div style="position: absolute; top: -9px; left: 314px; color: #8a93a4; font-size: 13px">
                  <img src="../../../../assets/images/icon_warn.png" alt="" style="position: relative; top: 2px" />
                  结算时计算利息的结束日
                </div>
                <el-date-picker v-model="formData.settleDate" type="date" placeholder="请选择结算日期" prefix-icon=" " style="width: 100%"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div>
      <div class="claim_company">
        <el-table
          :show-summary="showSummary"
          :summary-method="summaryFn"
          :data="formData.productDtos"
          border
          class="egrid erp-table__list product_table_total erp-view__table"
        >
          <el-table-column label="销售合同号" prop="businessContractNo" align="center" class-name="no-pd" show-overflow-tooltip width="176">
            <template #default="scope">
              <div>{{ filterName(scope.row.businessContractNo) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="国家" prop="countryName" class-name="no-pd" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.countryName) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="厂号" prop="plantNo" class-name="no-pd" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              <div>{{ filterName(scope.row.plantNo) }}</div>
            </template>
          </el-table-column>

          <el-table-column label="中文品名" prop="nameCn" class-name="no-pd" align="center" show-overflow-tooltip>
            <template #default="scope">
              <div>{{ filterName(scope.row.nameCn) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="入库件数" prop="instoreCount" class-name="no-pd" align="center" show-overflow-tooltip width="90">
            <template #default="scope">
              <div>{{ filterName(scope.row.instoreCountDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="剩余件数" prop="leftCount" align="center" class-name="no-pd" show-overflow-tooltip width="90">
            <template #default="scope">
              <div>{{ filterName(scope.row.leftCountDesc) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="赎货件数" prop="expectCount" align="center" class-name="no-pd req" show-overflow-tooltip width="100">
            <template #default="scope">
              <el-input v-model="scope.row.expectCount" placeholder="" :maxlength="4" @blur="getPlantCount($event, scope.row, 'expectCount')"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="赎货净重(kg)" prop="expectWeight" align="center" class-name="no-pd" show-overflow-tooltip width="110">
            <template #default="scope">
              <div>
                {{ filterName(_thousandBitSeparator(scope.row.expectWeight)) }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import RegexLib from 'mali-lib-starter/lib/regex-lib.js';
import { formatTime } from '@/utils/publicMethods';
export default {
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      ajaxLoading: false,
      showSummary: true,
      formData: {
        id: null,
        customerId: '',
        customerName: '',
        settleDate: '',
        version: '',
        productDtos: {
          stockId: '',
          stockProductId: '',
          orderId: '',
          unitPrice: '',
          tradeCurrency: '',
          unitWeight: '',
          country: '',
          countryName: '',
          plantNo: '',
          nameCn: '',
          nameEn: '',
          instoreCount: '',
          leftCount: '',
          expectCount: null,
        },
      },
      rules: {
        // 检验规则
        settleDate: [
          {
            required: true,
            message: '请选择结算日期',
            trigger: 'change',
          },
        ],
      },
    };
  },

  created() {
    const data = JSON.parse(JSON.stringify(this.editData));
    if (this.formType === 23) {
      this.getinitdata();
    } else {
      if (this.formType === 2) {
        data.productDtos.forEach((v) => {
          v.expectCount = null;
          v.instoreCountDesc = this._thousandBitSeparator(v.instoreCount);
          v.leftCountDesc = this._thousandBitSeparator(v.leftCount);
          v.expectCountDesc = null;
          v.expectWeightDesc = null; // 这里修改为取剩余重量
        });
      } else {
        data.productDtos.forEach((v) => {
          let total = 0;
          if (!v.instoreCount) {
            total = 0;
          } else {
            total = Number((v.unitWeight * v.expectCount) / v.instoreCount);
            total = total === 0 ? 0 : this._formatFloat(total, 3);
          }
          v.expectWeight = total;
          v.expectWeightDesc = this._thousandBitSeparator(total);
        });
      }
      this.formData = data;
      if (this.formData.productDtos.length > 1) {
        this.showSummary = true;
      } else {
        this.showSummary = false;
      }
    }
  },
  methods: {
    getPlantCount(e, row, name) {
      let value = e.target.value;
      value = RegexLib.regexRangeNumber(value, {
        min: 0,
        max: row.leftCount,
        decimal: 0,
      });
      row[name] = Number(value);
      const expectWeightDesc = Number((Number(row.instoreWeight) * Number(row.expectCount)) / Number(row.instoreCount));
      row.expectWeight = this._formatFloat(expectWeightDesc, 3);
    },
    getinitdata() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/outStoreRedeem/redeemDetail/' + this.editData.id,
        data: null,
        success: (res) => {
          if (res.code === 200) {
            // this.successTip('保存成功');
            this.formData = res.result;
            this.formData.productDtos = res.result.productDetailVos;
            this.formData.productDtos.forEach((e) => {
              const expectWeightDesc = Number(Number(e.unitWeight) * Number(e.expectCount));
              e.instoreCountDesc = this._thousandBitSeparator(e.instoreCount);
              e.leftCountDesc = this._thousandBitSeparator(e.leftCount);
              e.expectCountDesc = this._thousandBitSeparator(e.expectCount);
              e.expectWeight = this._formatFloat(expectWeightDesc, 3);
              e.expectWeightDesc = this._thousandBitSeparator(this._formatFloat(expectWeightDesc, 3));
            });
            if (this.formData.productDtos.length > 1) {
              this.showSummary = true;
            } else {
              this.showSummary = false;
            }
            // this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    summaryFn() {
      // 自定义过显示合计的方法
      const summaryColumns = ['合计', '', '', '', '', '', '', '', '', '', '', '', ''];
      const data = this.formData.productDtos;
      const instoreCountTotal = data.reduce((total, item) => {
        const instoreCount = Number(item.instoreCount);
        return total + instoreCount;
      }, 0);

      const leftCountTotal = data.reduce((total, item) => {
        const leftCount = Number(item.leftCount);
        return total + leftCount;
      }, 0);

      const outWeightTotal = data.reduce((total, item) => {
        const outWeightTotal = item.expectWeight ? Number(item.expectWeight) : 0;
        return total + outWeightTotal;
      }, 0);
      const expectCount = data.reduce((total, item) => {
        const expectCount = Number(item.expectCount);
        return total + expectCount;
      }, 0);

      summaryColumns[4] = this._thousandBitSeparator(instoreCountTotal);
      summaryColumns[5] = this._thousandBitSeparator(leftCountTotal);
      summaryColumns[6] = this._thousandBitSeparator(expectCount);
      summaryColumns[7] = this._thousandBitSeparator(this._formatFloat(outWeightTotal, 3));

      return summaryColumns;
    },
    validForm() {
      const data = this.formData.productDtos;
      const status = data.some((v) => {
        return v.expectCount === null || v.expectCount === '' || v.expectCount > v.leftCount;
      });
      if (status === true) {
        this.errorTip('赎货件数需小于等于剩余件数');
        return false;
      } else {
        return true;
      }
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.validForm()) {
            if (!this.ajaxLoading) {
              this.ajaxLoading = true;
              this.saveSubmitForm();
            }
          }
        } else {
          this.errorTip('请填写所有带星号的必填项');
        }
      });
    },
    saveSubmitForm() {
      // 保存或编辑表单
      const params = JSON.parse(JSON.stringify(this.formData));
      params.settleDate = formatTime(params.settleDate, 'YYYY-MM-DD');

      this.ajax({
        type: 'POST',
        url: '/malicrm/sm/stock/operateRedeem',
        data: {
          ...params,
        },
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功');
            this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
            this.ajaxLoading = false;
          } else {
            this.ajaxLoading = false;
            this.errorTip(res.message);
          }
        },
      });
    },
    back() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped></style>
