<template>
  <div>
    <div v-if="isshow">
      <div class="erp-form__header header-bar__sticky">
        <div class="erp-form__title">预约出库</div>
        <div class="erp-form-bar">
          <div class="erp-primary__btn" @click="submit">保存</div>
          <div class="erp-default__btn" @click="back">返回</div>
        </div>
      </div>
      <div class="erp-form__body">
        <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
          <div class="mali-edit__form" style="width: 100%">
            <el-row :gutter="16" type="flex">
              <el-col :span="12">
                <el-form-item label="出库日期" prop="outstoreDate">
                  <el-date-picker v-model="formData.outstoreDate" type="date" placeholder="请选择出库日期" prefix-icon=" " style="width: 100%"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="结算日期" prop="settleDate">
                  <div style="position: absolute; top: -9px; left: 310px; color: #8a93a4; font-size: 13px">
                    <img src="../../../../assets/images/icon_warn.png" alt="" style="position: relative; top: 2px" />
                    结算时计算利息的结束日
                  </div>
                  <el-date-picker v-model="formData.settleDate" type="date" placeholder="请选择结算日期" prefix-icon=" " style="width: 100%"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="车牌号">
                  <el-input
                    v-model="formData.plateNumber"
                    :maxlength="8"
                    clearable
                    @blur="changePlateNumber($event)"
                    placeholder="请输入车牌号"
                    class="mali-full__inputcom"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="挂车号" prop="trailerNo">
                  <el-input
                    v-model="formData.trailerNo"
                    :maxlength="7"
                    clearable
                    @blur="changeTrailerNumber($event)"
                    placeholder="请输入挂车号"
                    class="mali-full__inputcom"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="司机姓名">
                  <el-input
                    v-model="formData.driverName"
                    @blur="changeDriverName($event)"
                    clearable
                    :maxlength="4"
                    placeholder="请输入司机姓名"
                    class="mali-full__inputcom"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证号" prop="driverIdentityNumber">
                  <el-input
                    :maxlength="18"
                    v-model="formData.driverIdentityNumber"
                    @blur="changeDriverCar($event)"
                    clearable
                    placeholder="请输入身份证号"
                    class="mali-full__inputcom"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="司机手机">
                  <el-input
                    :maxlength="11"
                    v-model="formData.driverMobile"
                    clearable
                    @blur="changeDriverMobile($event)"
                    placeholder="请输入司机手机"
                    class="mali-full__inputcom"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否抄码" prop="isScan">
                  <el-select v-model="formData.isScan" class="mali-full__input" @change="isScanChange" placeholder="请选择是否抄码">
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否控货" prop="isControl">
                  <el-select v-model="formData.isControl" class="mali-full__input" @change="isControlChange" placeholder="请选择是否控货">
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注" prop="outstoreIssue">
                  <el-input v-model="formData.outstoreIssue" placeholder :maxlength="100" class="mali-full__input" type="textarea" resize="none" :rows="3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <!-- 处理需求编辑允许新增出库编辑产品 -->
      <!-- v-if="formType !==2" -->
      <div class="booking_add_main op-16">
        <img @click="addbook" src="../../../../assets/images/KHCFDC_add2.png" class="booking_add_img" alt style="" />
        <span @click="addbook" class="booking_add_title">添加</span>
      </div>
      <div class="">
        <el-table
          :show-summary="showSummary"
          :summary-method="summaryFn"
          :data="formData.productDtos"
          border
          :class="{ 'no-shadow': !shadow }"
          id="tables"
          class="egrid erp-table__list erp-view__table"
        >
          <el-table-column label="销售合同号" prop="businessContractNo" align="center" class-name="no-pd" show-overflow-tooltip>
            <template #default="scope">
              {{ filterName(scope.row.businessContractNo) }}
            </template>
          </el-table-column>
          <el-table-column label="国家" prop="countryName" class-name="no-pd" align="center" show-overflow-tooltip width="110">
            <template #default="scope">
              {{ filterName(scope.row.countryName) }}
            </template>
          </el-table-column>
          <el-table-column label="厂号" prop="plantNo" class-name="no-pd" align="center" show-overflow-tooltip width="120">
            <template #default="scope">
              {{ filterName(scope.row.plantNo) }}
            </template>
          </el-table-column>
          <el-table-column label="中文品名" prop="nameCn" class-name="no-pd" align="center" show-overflow-tooltip>
            <template #default="scope">
              {{ filterName(scope.row.nameCn) }}
            </template>
          </el-table-column>
          <el-table-column label="入库件数" prop="instoreCount" class-name="no-pd" align="center" show-overflow-tooltip width="100">
            <template #default="scope">
              {{ filterName(getThoundand(scope.row.instoreCount)) }}
            </template>
          </el-table-column>
          <el-table-column label="剩余件数" prop="leftCount" align="center" class-name="no-pd" show-overflow-tooltip width="90">
            <template #default="scope">
              {{ filterName(getThoundand(scope.row.leftCount)) }}
            </template>
          </el-table-column>
          <el-table-column label="出库件数 " prop="expectCount" align="center" class-name="no-pd required importnice_num" show-overflow-tooltip width="110">
            <template #default="scope">
              <el-input
                v-model="scope.row.expectCount"
                placeholder
                clearable
                :maxlength="4"
                class="erp_fnt_14"
                @blur="changeCount($event, 'expectCount', scope)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="出库净重(kg)" prop="outWeight" align="center" class-name="no-pd" show-overflow-tooltip width="100">
            <template #default="scope">
              {{ filterZero(_thousandBitSeparator(scope.row.outWeight)) }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" width="48" label="" class-name="no-pd" align="center">
            <template #default="scope">
              <span class="list-del" @click="deleteProduct(scope.$index)">
                <img src="../../../../assets/images/list-del.png" alt />
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-else>
      <addgoodform :editData="formData" @center="centeraddform" @get="getAddData"></addgoodform>
    </div>
  </div>
</template>
<script>
import { formatTime } from '@/utils/publicMethods';
import addgoodform from './addgoodform.vue';
export default {
  components: {
    addgoodform,
  },
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      shadow: true,
      showSummary: true,
      isshow: false,
      submitTimer: null,
      isAjaxLoding: false,
      formData: {
        attachment: {
          id: null,
          name: null,
          url: null,
        },
        isScan: null,
        isControl: null,
        attachmentVos: [
          {
            id: null,
            name: null,
            url: null,
          },
        ],
        driverIdentityNumber: null,
        driverMobile: null,
        driverName: null,
        id: null,
        trailerNo: null, //挂车号
        outstoreDate: null,
        outstoreIssue: null,
        plateNumber: null,
        productDtos: [
          {
            actualCount: null,
            businessContractNo: null,
            containerNo: null,
            country: null,
            countryName: null,
            expectCount: null,
            id: null,
            instoreCount: null,
            instoreReceiptNo: null,
            isNeedCtrl: null,
            isNeedScan: null,
            leftCount: null,
            nameCn: null,
            outWeight: null,
            plantNo: null,
            sellStmtId: null,
            stockProductId: null,
            unitWeight: null,
          },
        ],
        settleDate: null,
        totalActualCount: null,
        totalInstoreCount: null,
        totalLeftCount: null,
        totalOutstoreCount: null,
        totalWeight: null,
        version: null,
      },
      rules: {
        trailerNo: [{ min: 7, max: 7, message: '长度为7个字符', trigger: ['blur', 'change'] }],
        outstoreDate: [
          {
            required: true,
            message: '请选择出库日期',
            trigger: 'change',
          },
        ],
        isScan: [
          {
            required: true,
            message: '请选择是否抄码',
            trigger: 'change',
          },
        ],
        isControl: [
          {
            required: true,
            message: '请选择是否控货',
            trigger: 'change',
          },
        ],
        settleDate: [
          {
            required: true,
            message: '请选择结算日期',
            trigger: 'change',
          },
        ],

        driverIdentityNumber: [],
        // 检验规则
        outCount: [
          {
            required: true,
            message: '请输入出库数量',
            trigger: 'change',
          },
        ],
      },
      needList: [
        {
          value: 0,
          label: '否',
        },
        {
          value: 1,
          label: '是',
        },
      ],
    };
  },

  created() {
    this.isshow = true;
    if (this.formType === 2) {
      this.getinitdata();
    } else {
      const data = JSON.parse(JSON.stringify(this.editData));
      if (this.editData.infundStatusName === '未垫资' || this.editData.infundStatusName === '已收款') {
        this.rules.settleDate = [];
      }
      data.productDtos.forEach((v) => {
        v.outWeight = 0;
        v.isNeedScan = null;
        v.isNeedCtrl = null;
      });

      this.formData = data;
      if (this.formData.productDtos.length > 1) {
        this.showSummary = true;
      } else {
        this.showSummary = false;
      }
    }
  },
  methods: {
    filterZero(val) {
      if (val === null || val === '') {
        return 0;
      } else {
        return val;
      }
    },
    deleteProduct(index) {
      this.$erpConfirm('确定删除吗？')
        .then(() => {
          this.formData.productDtos.splice(index, 1);
        })
        .catch(() => {
          return false;
        });
    },
    getThoundand(val) {
      if (!isNaN(val)) {
        const reg = /(\d)(?=(\d{3})+(?!\d))/g;
        const qStr = String(val).replace(reg, '$1,');
        return qStr;
      } else {
        return '';
      }
    },
    getinitdata() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/outStoreRedeem/outStoreDetail/' + this.editData.id,
        success: (res) => {
          if (res.code === 200) {
            this.formData = res.result;
            this.formData.storageId = this.editData.storageId; // 从列表把冷库id赋值 11-02
            this.formData.customerId = this.editData.customerId; // 从列表把客户id赋值 11-02
            this.formData.productDtos = JSON.parse(JSON.stringify(res.result.productDetailVos));
            if (this.formData.productDtos.length > 1) {
              this.showSummary = true;
            } else {
              this.showSummary = false;
            }
            this.formData.productDtos.forEach((v) => {
              const total = Number(Number(v.unitWeight) * Number(v.expectCount));
              v.outWeight = this._formatFloat(total, 3);
              v.outWeightDesc = this._thousandBitSeparator(v.outWeight);
              if (v.isNeedCtrl) {
                v.isNeedCtrl = 1;
              } else {
                v.isNeedCtrl = 0;
              }
              if (v.isNeedScan) {
                v.isNeedScan = 1;
              } else {
                v.isNeedScan = 0;
              }
            });
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    summaryFn() {
      // 自定义过显示合计的方法
      const summaryColumns = ['合计', '', '', '', '', '', '', '', '', '', '', '', ''];
      const data = this.formData.productDtos;
      const instoreCountTotal = data.reduce((total, item) => {
        const instoreCount = Number(item.instoreCount);
        return total + instoreCount;
      }, 0);

      const leftCountTotal = data.reduce((total, item) => {
        const leftCount = Number(item.leftCount);
        return total + leftCount;
      }, 0);

      let expectCountTotal = data.reduce((total, item) => {
        const expectCountTotal = Number(item.expectCount);
        return total + expectCountTotal;
      }, 0);
      if (isNaN(expectCountTotal)) {
        expectCountTotal = 0;
      }

      let outWeightTotal = data.reduce((total, item) => {
        const outWeightTotal = Number(item.outWeight);
        return total + outWeightTotal;
      }, 0);
      if (isNaN(outWeightTotal)) {
        outWeightTotal = 0;
      } else {
        outWeightTotal = Number(outWeightTotal) === 0 ? 0 : this._formatFloat(outWeightTotal, 3);
      }
      summaryColumns[5] = this._thousandBitSeparator(leftCountTotal);
      summaryColumns[4] = this._thousandBitSeparator(instoreCountTotal);
      summaryColumns[6] = this._thousandBitSeparator(expectCountTotal);
      summaryColumns[9] = this._thousandBitSeparator(outWeightTotal);

      return summaryColumns;
    },
    changeDriverCar(e) {
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z\d]/g, ''); // 清除中文英文以外的字符

      this.formData.driverIdentityNumber = value;
    },
    changePlateNumber(e) {
      let value = e.target.value;
      value = value.replace(/[^\w\u4E00-\u9FA5]/g, ''); // 中文、英文、数字

      this.formData.plateNumber = value;
    },
    changeTrailerNumber(e) {
      let value = e.target.value;
      value = value.replace(/[^\w\u4E00-\u9FA5]/g, '');
      this.formData.trailerNo = value;
    },
    changeDriverName(e) {
      let value = e.target.value;
      value = value.replace(/[^\u4e00-\u9fa5]/g, ''); // 只能输入中文

      this.formData.driverName = value;
    },
    changeDriverMobile(e) {
      let value = e.target.value;
      value = value.replace(/\D/g, ''); // 只能输入中文

      this.formData.driverMobile = value;
    },
    changeCount(e, name, scope) {
      let value = e.target.value;
      const index = scope.$index;
      const item = scope.row;
      value = value.replace(/[^\d]/g, ''); // 清除“数字以外的字符
      if (value > item.leftCount) {
        value = item.leftCount;
      }
      this.$nextTick(() => {
        if (value === '0' || value === '00' || value === '000' || value === '0000') {
          value = null;
        } else {
          value = value ? Number(value) : null;
        }
        this.formData.productDtos[index][name] = value;

        let total = 0;
        total = Number(Number(item.unitWeight) * Number(item.expectCount));

        if (isNaN(total)) {
          total = 0;
        } else {
          total = Number(total) === 0 ? '' : this._formatFloat(total, 3);
        }
        this.formData.productDtos[index].outWeight = total;
      });
    },
    getAddData(data) {
      this.isshow = true;
      data.forEach((v) => {
        v.outWeight = 0;
        v.isNeedScan = null;
        v.isNeedCtrl = null;
        // v.category =
        this.formData.productDtos.push(v);
      });
      if (this.formData.productDtos.length > 1) {
        this.showSummary = true;
      } else {
        this.showSummary = false;
      }
    },
    addbook() {
      // 新增产品
      this.isshow = false;
    },
    centeraddform() {
      this.isshow = true;
    },
    validateFormData() {
      // 校验数据是否符合业务
      const data = this.formData.productDtos;
      let status = true;
      for (let index = 0; index < data.length; index++) {
        const item = data[index];
        if (!item.expectCount) {
          this.errorTip('请填写所有带星号的必填项');
          status = false;
          break;
        }
        if (item.expectCount > 0 && item.expectCount > item.leftCount) {
          this.errorTip('出库件数需大于0且小于等于剩余件数');
          status = false;
          break;
        }
      }

      return status;
    },
    /**
     * 提交事件
     */
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const status = this.validateFormData();
          if (status) {
            if (!this.isAjaxLoding) {
              this.isAjaxLoding = true;
              this.saveSubmitForm();
            }
          }
        } else {
          this.errorTip('请填写所有带星号的必填项');
        }
      });
    },
    saveSubmitForm() {
      // 保存或编辑表单
      const params = JSON.parse(JSON.stringify(this.formData));
      params.settleDate = formatTime(params.settleDate, 'YYYY-MM-DD');
      params.outstoreDate = formatTime(params.outstoreDate, 'YYYY-MM-DD');
      this.ajax({
        type: 'POST',
        url: '/malicrm/sm/stock/operateOutStore',
        data: {
          ...params,
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.isAjaxLoding = false;
            this.successTip('保存成功');
            this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
          } else {
            this.errorTip(res.message);
            this.isAjaxLoding = false;
          }
        },
      });
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
    // 是否抄码change事件
    isScanChange(e) {
      if (e) {
        this.formData.isScanDesc = '是';
      } else {
        this.formData.isScanDesc = '否';
      }
    },
    // 是否控货change事件
    isControlChange(e) {
      if (e) {
        this.formData.isControlDesc = '是';
      } else {
        this.formData.isControlDesc = '否';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.booking_add_main {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.booking_add_img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  top: -1px;
}

.booking_add_title {
  text-align: right;
  color: #2878ff;

  cursor: pointer;
  font-size: 14px;
}
</style>
