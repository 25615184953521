<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">添加预约出库货物</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="submit">确认</div>
        <div class="erp-default__btn" @click="back">取消</div>
      </div>
    </div>
    <div class="add_good_form" style="margin-left: 16px">
      <div class="add_good_input">
        <el-input
          :maxlength="20"
          v-model="formData.businessContractNo"
          clearable
          placeholder="搜索销售合同号"
          class="mali-full__inputcom"
          size="small"
        ></el-input>
        <el-input
          style="margin-left: 10px"
          :maxlength="20"
          v-model="formData.nameCn"
          clearable
          placeholder="搜索中文品名"
          class="mali-full__inputcom"
          size="small"
        ></el-input>
      </div>
      <div class="add_good_btn">
        <div class="erp-primary__btn" @click="search">搜索</div>
        <div class="erp-default__btn ml-8" @click="reset">重置</div>
      </div>
    </div>
    <div>
      <el-table
        :data="outProductList"
        @selection-change="handleSelectionChange"
        border
        class="erp-table__list erp-view__table"
      >
        <el-table-column
          type="selection"
          width="55"
          class-name="selection"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="销售合同号"
          prop="businessContractNo"
          align="center"
          class-name="no-pd"
          show-overflow-tooltip
          width="176"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.businessContractNo) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="国家"
          prop="countryName"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="100"
        >
          <template #default="scope">
            <div>
              {{ filterName(scope.row.countryName) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="厂号"
          prop="plantNo"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="100"
        >
          <template #default="scope">
            <div>
              {{ filterName(scope.row.plantNo) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="中文品名"
          prop="nameCn"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
        >
          <template #default="scope">
            <div>
              {{ filterName(scope.row.nameCn) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="入库件数"
          prop="instoreCount"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="90"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.instoreCount) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="剩余件数"
          prop="leftCount"
          align="center"
          class-name="no-pd"
          show-overflow-tooltip
          width="90"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.leftCount) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="收款状态"
          prop="infundStatusName"
          align="center"
          class-name="no-pd"
          show-overflow-tooltip
          width="95"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.infundStatusName) }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editData: {
      type: [Object],
    },
  },
  data() {
    return {
      outProductList: [],
      selProductArr: [],
      formData: {
        // 绑定表单数据
        businessContractNo: null,
        nameCn: null,
        stockProductIds: [],
      },
    };
  },
  created() {
    this.formData.storageId = this.editData.storageId;
    this.formData.category = this.editData.category;
    this.formData.customerId = this.editData.customerId;
    this.formData.customerId = this.editData.customerId;
    // this.formData.infundStatus = this.editData.infundStatus;
    this.editData.productDtos.forEach((item) => {
      this.formData.stockProductIds.push(item.stockProductId);
    });
    this.getOutStoreProductList();
  },

  methods: {
    reset() {
      this.formData.businessContractNo = '';
      this.formData.nameCn = '';
      this.getOutStoreProductList();
    },
    submit() {
      if (this.selProductArr.length > 0) {
        this.$emit('get', JSON.parse(JSON.stringify(this.selProductArr)));
      } else {
        this.errorTip('请选择出库货物');
      }
    },
    getOutStoreProductList() {
      const params = JSON.parse(JSON.stringify(this.formData));
      this.ajax({
        type: 'POST',
        url: '/malicrm/sm/stock/queryOutStoreProduct',
        data: {
          ...params,
        },
        loading: true,
        success: (res) => {
          this.outProductList = res.result;
        },
      });
    },
    /**
     * 列表复选框 选择事件
     */
    handleSelectionChange(val) {
      this.selProductArr = val;
    },
    back() {
      this.$emit('center', false);
    },
    search() {
      this.getOutStoreProductList();
      // this.$emit('center');
    },
  },
};
</script>

<style lang="scss" scoped>
.add_good_form {
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  margin-bottom: 30px;
}

.add_good_input {
  display: flex;
  width: 375px;
}

.add_good_btn {
  display: flex;
  margin-left: 10px;
  flex: 1;
}
</style>
